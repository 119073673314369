.values-container {
    background-color: #DDE1E0;
    display: flex;
    flex-direction: column;
}

.values-container h1 {
    text-align: center;
    color: #4A6B5F;
}

.value-columns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.value-column {
    flex-basis: 30%;
    text-align: center;
}

.value-column .headline {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.value-column .text {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
}

@media only screen and (max-device-width : 480px) {
    .value-column {
        flex-basis: 100%;
    }
}
