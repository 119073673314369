.about-us-container {
    background-color: #DDE1E0;
    scroll-margin-top: 40px;
}

.about-us-columns h1 {
    margin-bottom: 16px;
}
.about-us-columns p {
    margin-top: 0;
}

.about-us-columns {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.about-us-column {
    flex-basis: 50%;
    flex-grow: 1;
    text-align: left;
}

.about-us-column img {
    width: 515.13px;
    height: 359px;
}

.image-column {
    text-align: center;
    align-self: flex-start;
}

.about-us-column .instagram {
    display: flex;
    align-items: center;
}

.about-us-column svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: #4A6B5F;
}

@media only screen and (max-device-width : 480px) {
    .about-us-columns{
        flex-direction: column-reverse;
    }

    .about-us-column img {
        width: 100%;
        max-height: 300px;
        object-fit: cover;
        margin-bottom: 16px;
    }

    .image-column {
        margin: 0 -20px;
    }

    .ingredients-column ul {
        font-size: 16px;
        line-height: 1.2;
    }
}
