.contact-form {
    scroll-margin-top: 40px;
}

.contact-form .hidden-form {
    display: none;
}

.contact-form form {
    display: flex;
    flex-direction: column;
}

.contact-form form {
    display: flex;
    flex-direction: column;
}

.contact-form form label {
    margin-bottom: 10px;
    font-size: 14px;
}

.contact-form form input {
    width: 350px;
    min-height: 40px;
    margin-bottom: 24px;
    border-color: #4A6B5F;
    border-style: solid;
    border-width: 1px;
}

.contact-form textarea {
    width: 600px;
    min-height: 150px;
    margin-bottom: 24px;
    border-color: #4A6B5F;
    border-style: solid;
    border-width: 1px;
}

.contact-form button {
    width: 122px;
    height: 46px;
    font-size: 18px;
    text-transform: uppercase;
    font-family: 'Roboto-Mono';
    background: #F1C171;
    border-radius: 8px;
    border-width: 1px;
    cursor: pointer;
    color: #4B4B4B;
    border-style: none;
    border-color: transparent;
}

.contact-form button:hover {
    filter: brightness(0.95);
}

.contact-form .checkbox-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
}

.contact-form .checkbox-container input {
    margin: 0 10px 0 0;
    width: 30px;
    height: 30px;
    border-color: #4A6B5F;
    border-style: solid;
    border-width: 1px;
}

.contact-form .checkbox-container label {
    margin: 0;
}

@media only screen and (max-device-width : 480px) {
    .contact-form form input,
    .contact-form button,
    .contact-form textarea
     {
        width: 100%;
    }
}
