@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Mono';
  src: url('./assets/fonts/RobotoMono-Regular.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

html,
body {
  width: 100%;
  margin: 0;
  overflow-x: hidden;
  color: #4B4B4B;
}

body {
  font-family: 'Montserrat';
  display: flex;
  flex-flow: column;
}

h1 {
  font-weight: 400;
  font-size: 40px;
}

h1, h2, h3 {
    font-family: 'Roboto-Mono';
    text-transform: uppercase;
    color: #4B4B4B;
}

 a {
  text-decoration: none;
  font-family: 'Roboto-Mono';
  color: #4A6B5F;
  font-weight: 500;
  font-size: 16px;
}

a:hover {
  text-decoration: underline;
  font-family: 'Roboto-Mono';
  color: #4A6B5F;
  font-weight: 500;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.section {
  padding: 40px 80px 70px 80px;
}

.content-container {
  max-width: 1280px;
  margin: 0 auto;
}

@media only screen and (max-device-width: 480px) {
  .section {
    padding: 30px 20px;
  }

  h1 {
    font-size: 32px;
  }
}
