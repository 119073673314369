header {
    height: 100vh;
    background-image: url('../assets/hero.jpg');
    background-size: cover;
    background-position: 70%;
    background-repeat: no-repeat;
    position: relative;
}

header img {
    width: 53.85px;
    height: 26.56px;
}

.navigation-container {
    display: flex;
    padding: 2% 5%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav ul {
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

nav ul li:not(:last-child) {
    margin-right: 44px;
}

nav ul li a {
    font-weight: 500;
    font-size: 16px;
}

nav ul li a:hover {
    font-weight: 500;
}

@media only screen and (max-device-width: 480px) {
    .navigation-container {
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 20px;
        padding-block-end: 0;
        background-color: rgba(255, 255, 255, 0.9);
    }

    header img {
        width: 100%;
        height: auto;
    }

    header img {
        width: 80px;
        height: auto;
    }

    nav ul {
        padding: 0;
    }

    header {
        height: 80vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
    }

}
