.ingredients-columns {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.ingredients-column img {
    width: 565px;
    height: 637px;
}

.ingredients-column {
    flex-basis: 50%;
    text-align: center;
}

.ingredients-column ul {
    font-size: 24px;
    line-height: 29px;
}

.ingredients-column li {
    margin-bottom: 16px;
}

.ingredients-column ul,
.ingredients-column h1 {
    text-align: left;
}

@media only screen and (max-device-width : 480px) {
    .ingredients-columns{
        flex-wrap: wrap;
    }

    .ingredients-column {
        flex-basis: 100%;
    }

    .ingredients-column img {
        width: 100%;
        max-height: 300px;
        object-fit: cover;
    }

    .ingredients-column ul {
        font-size: 16px;
        line-height: 1.2;
    }
}
