.footer {
    background-color: #DDE1E0;
    padding: 0;
}

footer .overlay {
    max-height: 300px;
    width: 100%;
    object-fit: cover;
}

footer svg path {
    fill: #4A6B5F;
}

footer .content {
    padding: 40px 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 20px;
    color: #4A6B5F;
}

footer .content a {
    font-size: 20px;
}

footer .content .instagram {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

footer .content .instagram svg {
    margin-right: 10px;
    height: 30px;
    width: 30px;
}

footer .content .logo svg {
    margin-left: 10px;
    margin-bottom: -10px;
}

footer .content .logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.copyright {
    font-size: 16px;
}

@media only screen and (max-device-width : 480px) {
    footer .content {
        gap: 16px;
        padding: 40px 20px;
        font-size: 14px;
    }

    footer .content .content-column {
        flex-basis: 100%;
        text-align: center;
    }

    .copyright {
        padding-top: 20px;
    }
}
