.overview-container {
    scroll-margin-top: 40px;
}

.overview-container .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.overview-container img {
    width: 100%;
}

.overview-container h1 {
    margin-bottom: 13px;
}

.overview-container .text {
    font-size: 22px;
}

.overview-container .cta-wrapper {
    display: flex;
    align-items: center;
}

.overview-container .cta-wrapper h2 {
    font-size: 30px;
}

.overview-container a {
    font-size: 30px;
    text-transform: uppercase;
    margin-left: 22px;
    text-decoration: underline;
}


@media only screen and (max-device-width : 480px) {
    .overview-container .cta-wrapper {
        flex-direction: column;
    }

    .overview-container{
        text-align: center;
    }

    .overview-container a {
        margin-left: 0px;
    }
}
